import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm:any;
  otpFlag:any = 0;
  loginForm1;
  setupForm;
  ClearDbForm;
  username;
  tempOptionModel
  programs: any = [];
  errorMessage: Boolean = true
  errorMessageOtp: Boolean = true
  submitted = false;
  passwordSubmitted = false;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService,
    public modalService: NgbModal,
    public location: Location
  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({

      username:['', [ Validators.required,
        Validators.pattern("^[0-9]*$")]],
      otp: ['', [Validators.pattern("^((\\+91-?) |0)?[0-9]{6}$")]],
    });




  }
  get c() { return this.loginForm.controls; }

  ngOnInit() {

  }

  checkLogin() {
    // debugger
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    if (login == 'true') {
      this.router.navigateByUrl(previousURL)
    }
  }
  getPrograms() {
    this.apiService.get(this.apiConfig.get_all_the_programs)
      .subscribe((res: any) => {
        this.programs = res.data;
      }, err => {
        
      })
  }



  login(formData) {
    //debugger
    // this.errorMessage = false;
    
    this.submitted = true;

    if (this.loginForm.invalid || (this.otpFlag == 1  && formData.otp == '')) {
      
      if(this.otpFlag == 1 && formData.otp ==''){
        this.errorMessageOtp = false
        console.log('login');
        // return 
      }
      if(this.otpFlag == 0){
        console.log('otp dfghjkl',formData.username);
        this.errorMessage = false
        // formData.username == '' ? this.errorMessage = false : this.errorMessage = true; 
      }
      return
     
    }
    window.localStorage.setItem('program', JSON.stringify({ program_id: 299 }));

    let apiurl = this.apiConfig.login;
    let apiLogin = this.apiConfig.loginOTP;
    console.log(this.otpFlag,"otpflag");
    
    if (this.otpFlag == 0){
      let payload =({"username":formData.username});

      console.log(payload,"payload");
      // return
      this.apiService.post(apiLogin, payload)
      .subscribe((res: any) => {
        console.log(res.body);
        this.errorMessage = true;
        this.otpFlag = 1;
        this.toast.Success('OTP Sent');
      },
      err=>{
        console.log(err,"erro")
        // this.errorMessage = false;
      })
    }
    if(this.otpFlag == 1){
      
      let body = JSON.stringify({ "LoginForm": { "username": formData.username, "login_type": 2, "program_id": 299 ,"otp":formData.otp} });
      console.log(body);

      this.apiService.post(apiurl, body)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.errorMessageOtp = true;
          let body = res.body;
          this.toast.Success('Login successful');
          localStorage.setItem("user_name", (res.body.data.user_name));
          localStorage.setItem("user_id", (res.body.data.id));
          window.localStorage.setItem("program", JSON.stringify(res.body.data));
          this.auth.saveAuth(body.data.access_token);
          this.submitted = false;
          
          this.router.navigate(['/asm/dashboard'])
        }
      }, err => {
        console.log("erro")
        // this.errorMessageOtp = false;
        // 
      })
    }
    
  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }




  numberOnly(event): boolean {
    console.log(event)
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57){
      return false;
    }
    return true;

  }


}


